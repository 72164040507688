import Cookie from 'js-cookie'

const getJSONCookie = name =>
  Cookie.get(name)
    ? JSON.parse(decodeURIComponent(Cookie.get(name)))
    : { count: 0, shoppingCartItems: [], total: 0 }

const state = {
  order: getJSONCookie('cart_order_infos'),
  supplyOrder: getJSONCookie('cart_order_infos')
}

const mutations = {
  receiveFromAPI(state, { order, supplyOrder }) {
    state.order = order || state.order
    state.supplyOrder = supplyOrder || state.supplyOrder
  }
}

const actions = {
  reloadFromJSON: {
    root: true,
    handler({ commit }, { cart = {} }) {
      commit('receiveFromAPI', cart)
    }
  },
  async reload({ commit }) {
    const response = await fetch(location.origin + '/api/cart')
    const data = (await response.json()).data
    commit('receiveFromAPI', data)
  },
  async add({ commit }, { type, articleId, quantity }) {
    const response = await fetch(location.origin + '/api/cart', {
      method: 'PUT',
      body: JSON.stringify({ type, articleId, quantity })
    })
    const result = await response.json()

    const { data = {} } = result
    commit('receiveFromAPI', data)
  },
  async set({ commit }, { type, articleId, quantity }) {
    const response = await fetch(location.origin + '/api/cart', {
      method: 'POST',
      body: JSON.stringify({ type, articleId, quantity })
    })

    const { data = {} } = await response.json()
    commit('receiveFromAPI', data)
  },
  async remove({ commit }, { type, articleId }) {
    const response = await fetch(location.origin + '/api/cart', {
      method: 'DELETE',
      body: JSON.stringify({ type, articleId })
    })

    const { data = {} } = await response.json()
    commit('receiveFromAPI', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
