<template>
  <div
    ref="badge"
    class="badge badge-light shadow-sm"
    v-cloak
    v-if="number"
    v-text="(number < 100) ? number : '99+'"
  />
</template>

<script>
import $ from 'jquery'

export default {
    props: {
        number: { type: Number }
    },
    watch: {
        number (number) {
            $(this.$refs.badge).removeClass('jump')
            setTimeout(() => {
                $(this.$refs.badge).addClass('jump')
            })
        }
    }
}
</script>
