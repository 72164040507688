<template>
    <div v-cloak v-show="lastActionTimestamp" ref="notif" class="bg-primary notification notification-with-icon cart-notification">
        <section>
                <div class="d-flex align-items-center">
                    <div class="icon pr-2">
                        <a class="btn btn-icon" v-show="lastAction.type == 'order'" href="/checkout/order/cart">
                            <span class="ic ic-move-down ic-cart-buy"></span>
                        </a>
                        <a class="btn btn-icon" v-show="lastAction.type == 'supplyOrder'" href="/checkout/supplyOrder/cart">
                            <span class="ic ic-move-down ic-cart-sell2"></span>
                        </a>
                    </div>
                    <div class="label">
                        <div class="text-truncate">Produkt wurde zum Warenkorb {{ lastAction.type === 'order' ? 'Kaufen' : 'Verkaufen' }} hinzugefügt</div>
                        <div class="text-truncate text-muted">{{ lastAction.qty }} x {{ lastAction.articleLabel }}</div>
                        <div class="icon-text-replacement d-none"><a :href="linkToCart">Zum Warenkorb</a></div>
                    </div>
                </div>
        </section>
    </div>
</template>

<script>
import $ from 'jquery'
import { LAST_ACTION_TIMEOUT } from '../../store/modules/cart';

let timeout = null;

export default {
  computed: {
    lastAction () { return this.$store.state.cart?.lastAction },
    lastActionTimestamp () { return this.lastAction.timestamp },
    linkToCart () { return this.lastAction.type == 'order' ? '/checkout/order/cart' : '/checkout/supplyOrder/cart' }
  },
  watch: {
    lastActionTimestamp(ts) {
        if (ts) {
            setTimeout(() => {
                $(this.$refs.notif).addClass('enabled');
            }, 10)

            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                $(this.$refs.notif).removeClass('enabled');
            }, LAST_ACTION_TIMEOUT - 250);
        }
    }
  }
}
</script>
