<template>
  <form
    v-on:submit.prevent="handleSubmit"
    ref="form"
    @click="onClick"
    class="ajax-form"
    v-bind:class="{ loading: submissionState.loading, hidden: this.visibilityHidden }"
    @change="this.onChange"
  >
    <slot
      v-bind:submissionState="submissionState"
      v-bind:formData="formData"
      v-bind:errorData="errorData"
      v-bind:responseData="responseData"
    ></slot>
    <div class="ajax-form__overlay">
      <div class="loading-animation" v-bind:class="{ loading: submissionState.loading }">
        <span class="lnr lnr-sync"></span>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import set from 'lodash/set'

function serialize (form) {
    var data = { useGroups: [] }
    $(form).serializeArray().forEach(({ name, value}) => {
        set(data, name, value)
    })
    return data
}

export default {
    props: {
        action: {
            type: Function,
            required: true,
        },
        visibilityHidden: {
            type: Boolean,
            required: false,
        },
        reloadOnSuccess: {
            type: Boolean,
            default: false
        },
        autoReset: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            submissionState: {
                loading: false,
                error: false,
                success: false
            },
            formData: {

            },
            errorData: {
                message: ''
            },
            responseData: {}
        }
    },

    methods: {
        handleSubmit() {
            if (this.submissionState.loading) return

            this.submissionState.success = false
            this.submissionState.error = false
            this.submissionState.loading = true
            this.errorData.message = ''
            this.responseData = {}

            this.action(this.formData).then(data => {
                this.handleSuccess(data)
            }).catch(data => {
                this.handleError(data)
            })
        },
        handleSuccess(data) {
            if (this.autoReset) {
                this.$refs.form.reset();
                Object.keys(this.formData).forEach(key => this.formData[key] = undefined)
            }
            if (this.reloadOnSuccess) {
                location.reload()
            } else {
                this.submissionState.loading = false
                this.submissionState.success = true
                this.responseData = data
            }
        },
        onChange () {
            this.formData = { ...this.formData, ...serialize(this.$refs.form) };
        },
        onClick (e) {
            let target = e.target;
            while (target && target.nodeName !== 'BUTTON') {
                target = target.parentNode;
            }
            if ( target && target.nodeName === 'BUTTON' && target.name ) {
                this.formData[target.name] = target.value
            }
        },
        handleError(message) {
            this.submissionState.loading = false
            this.submissionState.error = true
            this.errorData.message = message
        }
    },

    mounted () {
        this.formData = { ...this.formData, ...serialize(this.$refs.form) }
    },

    updated () {
        const formData = { ...this.formData, ...serialize(this.$refs.form) }
        if (JSON.stringify(formData) != JSON.stringify(this.formData)) {
            this.formData = formData
        }
    }
}
</script>
