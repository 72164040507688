<template>
  <div class="container pt-3 py-5">
    <div class="header__counter-adress flex-1 text-center w-100">
      <div>
        <p class="h3">{{ name }} Hotline</p>
        <a class="text-link" v-bind:href="phone | phoneLink">{{ phone }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        selected () {
            return this.$store.state.subsidiaries.selected
        },
        name () {
            return this.selected ? this.selected.name : 'Unsere'
        },
        phone () {
            return this.selected ? this.selected.phone : this.$store.state.subsidiaries.phoneOnline
        }
    }
}
</script>

