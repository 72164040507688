export const getSearchParams = () => {
    let params = {};
    (window.location.search || "")
        .substring(1)
        .split("&")
        .forEach((part) => {
            let [key, value] = part.split("=");
            params[decodeURIComponent(key)] = decodeURIComponent(value);
        });
    return params;
};

export const getSearchParam = (name) => {
    return getSearchParams()[name] || null;
}