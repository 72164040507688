import Cookie from 'js-cookie'

const username = Cookie.get('user')

const state = {
    isLoggedIn: !!username,
    username
}

const mutations = {
    receiveFromAPI (state, { isLoggedIn, username }) {
        state.isLoggedIn = isLoggedIn || state.isLoggedIn
        state.username = username || state.username
    }
}

const actions = {
    reloadFromJSON: {
        root: true,
        handler ({ commit }, { user = {} }) { commit('receiveFromAPI', user) }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}