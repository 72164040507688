<template>
  <form
    v-on:mp-validated.prevent="handleSubmit"
    ref="form"
    class="ajax-form"
    v-bind:class="{ loading: submissionState.loading }"
    @change="this.onChange"
    @setInputValue="this.setInputValue"
  >
    <slot
      v-bind:submissionState="submissionState"
      v-bind:errorData="errorData"
      v-bind:responseData="responseData"
      v-bind:formData="formData"
      v-bind:setInputValue="setInputValue"
    ></slot>
    <div class="ajax-form__overlay">
      <div class="loading-animation" v-bind:class="{ loading: submissionState.loading }">
        <span class="lnr lnr-sync"></span>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import set from 'lodash/set'
import each from 'lodash/each'
import { enableParsley } from '../../parts/validation'

function serialize (form) {
    var data = { useGroups: [] }
    $(form).serializeArray().forEach(({ name, value}) => {
        set(data, name, value)
    })
    return data
}

export default {
    props: {
        method: {
            type: String,
            required: false,
            default: 'GET'
        },
        action: {
            type: String,
            required: true,
        },
        reloadOnSuccess: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submissionState: {
                loading: false,
                error: false,
                success: false
            },
            errorData: {
                message: '',
                errors: []
            },
            responseData: {},
            formData: { useGroups: [] }
        }
    },

    methods: {
        setInputValue(name, value) {
            const el = $(this.$refs.form).find('[name="' + name + '"]');
            el.val(value).trigger('change');
            el.first().parsley().validate();
        },
        handleSubmit(e) {
            if (this.submissionState.loading) return

            this.submissionState.success = false
            this.submissionState.error = false
            this.submissionState.loading = true
            this.errorData.message = ''
            this.errorData.errors = []
            this.responseData = {}
            $(this.$refs.form).removeParsleyServerErrors()

            jQuery.ajax({
                url: this.action,
                type: this.method,
                data: jQuery(this.$refs.form).serialize(),
            }).done(response => {
                if (response && response.success) {
                    if (response.data.initStore) {
                        this.$store.dispatch('reloadFromJSON', response.data.initStore)
                    }
                    if (response.data.redirect) {
                        location.href = response.data.redirect
                    } else {
                        this.handleSuccess(response)
                    }
                } else {
                    if (response && response.message) {
                        this.handleError(response.message, response.errors)
                    } else {
                        this.handleError("Ungültige Server-Antwort")
                    }
                }
            }).fail((response, message) => {
                if (response.responseJSON && response.responseJSON.message) {
                    this.handleError(response.responseJSON.message, response.responseJSON.errors)
                } else {
                    this.handleError(message)
                }
            })
        },
        onChange () {
            this.formData = serialize(this.$refs.form);
        },
        handleSuccess(data) {
            if (this.reloadOnSuccess) {
                location.reload()
            } else {
                this.submissionState.loading = false
                this.submissionState.success = true
                this.responseData = data
            }
        },
        handleError(message, errors = {}) {
            this.submissionState.loading = false
            this.submissionState.error = true
            this.errorData.message = message
            this.errorData.errors = errors

            if (!!errors.form) {
                each(errors.form, (message, name) => {
                    // Todo if signal "#{" - do something with it, do not show it
                    if (typeof(message) == 'object' && message[0]) {
                        message = message[0];
                    }
                    $(this.$refs.form).addParsleyServerError(name, message);
                })
                $(this.$refs.form).parsley().validate()
            }
        }
    },

    mounted () {
      enableParsley($(this.$refs.form))
      this.formData = serialize(this.$refs.form)
    },

    updated () {
        const formData = serialize(this.$refs.form)
        if (JSON.stringify(formData) != JSON.stringify(this.formData)) {
            this.formData = formData
        }
    }
}
</script>
