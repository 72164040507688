import $ from 'jquery'
//import 'slick-carousel';
import debounce from 'lodash/debounce';

if (!$.fn.slick)
  $.fn.slick = function(...args) {
    if (this.length > 0) {
      import('slick-carousel').then(() => {
        if (this.selector) {
          $(this.selector).slick(...args)
        } else {
          this.slick(...args)
        }
      })
    }
    return this
  }

jQuery($ => {
  /*------------------ SLIDER ------------------ */

  //  Slider Stage
  $('.slider-stage').each(function() {
    var slider = $(this),
      dotswrap

    slider.on('init', function(event, slick) {
      dotswrap = slider
        .find('.slick-dots')
        .wrap('<div class="dots-wrap" />')
        .parent()
      slider.find('.slick-dots').wrap('<div class="container" />')
    })

    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 6500,
      speed: 700,
      pauseOnHover: false,
      dots: true,
      centerMode: true,
      centerPadding: '0px'
    })
  })

  // Slider Basic
  $('.slider-basic').slick({
    adaptiveHeight: true,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4800,
    pauseOnHover: true,
    fade: true,
    speed: 500,
    dots: true
  })

  // Slider Col
  $('.slider-col').slick({
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4800,
    speed: 500,

    slidesToScroll: 1
  })

  //console.log($('.slider-rates')[0])
  // Slider Rates
  $('.slider-rates').slick({
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4800,
    speed: 500,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 4,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  })

  initArticleSlider($);
  initSlickArrows($);
})


function initArticleSlider($) {
  // Slider Articles
  $('.slider-articles').each(function() {
    const slider = $(this);
    slider.slick({
      arrows: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 4800,
      speed: 500,
      dots: false,
      slidesToScroll: 2,
      slidesToShow: 4,

      responsive: [
        {
          breakpoint: slider.hasClass('narrow') ? 1400 : 1199,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: slider.hasClass('narrow') ? 1199 : 767,
          settings: {
            slidesToShow: 2
          }
        } /*,
        {
          breakpoint: slider.hasClass('narrow') ? 767 : 575,
          settings: {
            slidesToShow: 1
          }
        }*/
      ]
    })
    // Hide/Show arrows
    slider.on('init breakpoint', function(event, slick) {
      const nbSlidesShown = slick.slickGetOption('slidesToShow');
      const nbSlidesTotal = slider.find('.slick-slide').length;
      if (nbSlidesTotal > nbSlidesShown) {
        slider.closest('.js-slider-container').find('.slick-arrows').show();
      } else {
        slider.closest('.js-slider-container').find('.slick-arrows').hide();
      }
    })
  })  

  $(window).on('resize', debounce(onResizeArticles, 150));
}

function onResizeArticles() {
  jQuery('.slider-articles').slick('setDimensions');
  jQuery('.slider-articles').slick('resize');
  jQuery('.slider-articles').slick('refresh');
}

function initSlickArrows($) {
  $(document).on('click', '.js-prev, .js-next', onClick);

  function onClick(e) {
    let target = e.target;
    while (target && target.nodeName !== 'BUTTON') {
      target = target.parentNode;
    }
    const command = $(target).hasClass('js-prev') ? 'slickPrev' : 'slickNext';
    const slider = $(target).closest('.js-slider-container').find('.slider');

    slider.slick(command);
  }
}