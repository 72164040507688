<template>
    <div class="header__counter" :class="{ 'd-block': mobile, 'w-100': mobile, 'mb-3': mobile }">
        <div class="header__counter-button" :class="{ 'm-0': mobile }">
            <button
                type="button"
                class="btn btn-secondary btn-counter"
                :class="{ 'w-100': mobile }"
                data-fancybox
                data-src="#counter"
                id="choose-counter"
                v-text="selected ? selected.name : 'Filiale'"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mobile: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selected () {
            return this.$store.state.subsidiaries.selected
        },
        name () {
            return this.selected ? this.selected.name : 'Unsere'
        },
        phone () {
            return this.selected ? this.selected.phone : this.$store.state.subsidiaries.phoneOnline
        }
    }
}
</script>
