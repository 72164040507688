import $ from "jquery";

import "bootstrap/js/dist/util";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tab";
$("body").on("change", "select", function() {
  $(this).toggleClass("empty", !$(this).val());
});
$.fn.selectpicker = function(...args) {
  if (this.length > 0) {
    import("bootstrap-select").then(() => {
      $.fn.selectpicker.Constructor.BootstrapVersion = "4";
      this.selectpicker(...args);
      this.each(function() {
        $(this).toggleClass("empty", !$(this).val());
      });
    });
  }
};
$(".selectpicker")
  .not("[v-selectpicker]")
  .selectpicker({
    width: "css-width",
    container: "body",
    noneSelectedText: 'Bitte auswählen...'
  });

$.fn.scrollTo = function() {
  $("html, body").animate({ scrollTop: this.offset().top }, "slow");
  return this;
};


$(() => {
  if (document.getElementById("wpadminbar")) {
    document.body.className += " has-admin-bar";
  }
});

import "./3rdparty/jquery.fancybox";

// Parts
import "./parts/base";
import "./vue";

import "./parts/menu";
import "./parts/contentelements";
import "./parts/preview-images";
import "./parts/slider";
import "./parts/validation";
import "./parts/tabs";
import "./parts/scrolltotop";
import "./parts/usercentrics";
