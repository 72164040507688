<template>
    <form @submit.prevent="submit" class="async-input" :class="{ dirty: dirty, loading: loading }">
        <div class="input-group">
            <input class="form-control" :type="type" v-model="inputValue" :min="min" :max="max" />
            <div class="input-group-append">
                <button class="btn btn-secondary" type="submit">
                    <span class="lnr lnr-sync"></span>
                </button>
            </div>
        </div>
    </form>    
</template>

<script>
export default {
    props: {
        onChange: {
            type: Function,
            required: true
        },
        value: {
            required: false
        },
        type: {
            type: String,
            required: false
        },
        min: { required: false },
        max: { required: false }
    },

    data() {
        return {
            inputValue: this.value,
            dirty: false,
            loading: false
        }
    },

    watch: {
        inputValue(newValue) {
            this.dirty = newValue != this.value
        },
        value(newValue) {
            this.inputValue = newValue
            this.dirty = false
        }
    },

    methods: {
        submit() {
            if (this.loading) return
            this.loading = true
            this.onChange(this.inputValue).then(() => {
                this.inputValue = this.value
                this.loading = false
            }).catch(() => {
                this.inputValue = this.value
                this.loading = false
            })
        }
    }
}
</script>