<template>
  <DynamicScroller class="table--articles" :items="items" :min-item-size="132" page-mode key-field="permalink" emitUpdate @update="(startIndex, endIndex) => $emit('update', startIndex, endIndex)">
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem :item="item" :active="active" :data-index="index">
        <mp-availability-info :availability="item.availabilityByOrderTypeID" v-slot="{ availability }">
          <div class="table--articles__row" :class="{ 'bg-medium': index % 2 }" v-if="item.label">
            <div class="table--articles__image">
              <div class="img-square">
                <a :href="item.permalink">
                  <img :src="item.imageUrl" class="img-fluid" />
                </a>
              </div>
            </div>
            <div class="table--articles__name">
              <a :href="item.permalink">
                <b class="d-block text-body">
                  {{ item.label }}
                  <span v-if="item.year">({{ item.year }})</span>
                </b>
                <b class="d-block text-muted">{{ item.serie.label }}</b>
              </a>
            </div>
            <div class="table--articles__value">
              <div v-show="item.fineWeight !== '0 g'">
                <b>{{ item.fineWeight }}</b><br>
                <span>Feingewicht</span>
              </div>
              <div v-show="sortBy() == 'spread'">
                <b v-if="availability.anywhere === 'none'" title="nicht verfügbar" v-cloak>n.&nbsp;verf.</b>
                <b v-else>{{ item.priceInfo.spread | toPercent }}</b>
                <br>
                <span>Spread</span>
              </div>
              <div v-show="sortBy() == 'pricePerGramm'">
                <b v-if="availability.anywhere === 'none'" title="nicht verfügbar" v-cloak>n.&nbsp;verf.</b>
                <b v-else>{{ item.priceInfo.pricePerGramm | toCurrency }}&nbsp;€</b>
                <br>
                <span>pro Gramm</span>
              </div>
            </div>
            <div class="table--articles__price table--articles__price--sell">
              <b v-if="item.priceInfo.pricePurchase && item.canSell">{{ item.priceInfo.pricePurchase | toCurrency }}&nbsp;€</b>
              <b v-else>n.&nbsp;verf.</b>
              <a :href="item.permalink" class="text-link">verkaufen</a>
            </div>
            <div class="table--articles__price table--articles__price--buy">
              <b v-if="availability.anywhere === 'none'" title="nicht verfügbar" v-cloak>n.&nbsp;verf.</b>
              <b v-else>{{ item.priceInfo.priceGross | toCurrency }}&nbsp;€</b>
              <a :href="item.permalink" class="text-link">kaufen</a>
            </div>
            <div class="table--articles__status" v-cloak>
              <div class="d-flex flex-row">
                <span class="badge" :class="availability.online" title="Verfügbarkeit online">
                  <span class="lnr" :class="{
                      'lnr-checkmark-circle': availability.online !== 'none',
                      'lnr-cross-circle': availability.online === 'none'
                    }"></span>
                  <!-- lnr-checkmark-circle -->
                </span>
                <span class="badge ml-2" :class="availability.local" v-if="subsidiaries.selected" title="Verfügbarkeit in Ihrer Filiale">
                  <span class="lnr lnr-map-marker"></span>
                  <!-- lnr-checkmark-circle -->
                </span>
              </div>
            </div>
          </div>
        </mp-availability-info>
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import get from 'lodash/get'

export default {
  methods: {
    sortBy: function() {
      const sortBy = jQuery('#js-sortBy').val();
      return sortBy;
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    subsidiaries() {
      return get(this.$store, 'state.subsidiaries')
    } 
  }
}
</script>
