jQuery($ => {

	/*------------------ ACCORDION ------------------ */
  (function () {
    $('.accordion__heading').next().hide();

    function toggle (headings, show) {
      // Blendet den Inhalt zu einer oder meherern Überschriften abhängig von show ein und aus
      headings.toggleClass('accordion--active', show);
      headings.next().stop(true)[show ? 'slideDown' : 'slideUp'](300);
      headings.children('.glyph').toggleClass('glyphicons-plus', !show).toggleClass('glyphicons-minus', show);
    }

    $('body').on('click', '.accordion__heading', function () {
      var hl = $(this);
      if (hl.hasClass('accordion--active')) {
        toggle(hl, false);
      } else {
        toggle($('.accordion__heading').not(hl), false);
        toggle(hl, true);
      }
    });
  }());


  /*------------------ TABS ------------------ */
  $('a[data-toggle="tab"]').on('show.bs.tab', function () {
    $(this).closest('.collapse').collapse('hide');
  })

});
