import $ from "jquery";
$(document).on('touch click', '.js-back-to-top', function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
})

// From https://css-tricks.com/styling-based-on-scroll-position/

if (
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
) {
    let observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
            document.body.classList.add("header-not-at-top");
        } else {
            document.body.classList.remove("header-not-at-top");
        }
    });
    observer.observe(document.querySelector("#top-of-site-pixel-anchor"));
}
