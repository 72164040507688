jQuery($ => {
  /*------------------ FANCYBOX ------------------ */
  $.fancybox.defaults.parentEl = "body"
  $.fancybox.defaults.smallBtn = true
  $.fancybox.defaults.touch = false
  $.fancybox.defaults.btnTpl.smallBtn = `
    <button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">
    </button>
  `

  $(document).on('click', '.js-href-click', (e) => {
    const link = $(e.target).closest('.js-href-click').data('href');
    if (!link) {
      console.warn('Attribute data-href on .js-href-click is missing', e.target);
    }
    e.stopPropagation();
    e.preventDefault();
    window.location = link;
  })
  $(document).on('click', '.js-onclick-close', (e) => {
    jQuery.fancybox.close();
  })
});

window.mp_confirm = function mp_confirm (message, btnOk = "Ok", btnCancel = "Abbrechen") {
  return new Promise((resolve, reject) => {
    const html = jQuery(`
      <div class="confirmation-dialog">
        <section class="py-5"><div class="container">
          <p class="h3 message mb-0">${ message}</p>
        </div></section>
        <section class="py-4 bg-medium"><div class="container py-2">
          <div class="buttons d-md-flex flex-row-reverse">
          <button class="btn btn-danger            w-100 mb-md-0 ml-md-3">${ btnOk}</button>
          <button class="btn btn-outline-secondary w-100 mb-md-0">${ btnCancel}</button>
          </div>
        </div></section>
      </div>
    `)
    html.find('.btn-danger').click(() => {
      jQuery.fancybox.close();
      resolve()
    })
    html.find('.btn-outline-secondary').click(() => {
      jQuery.fancybox.close();
      reject()
    })
    jQuery.fancybox.open({ src: html, type: 'inline', modal: true })
  })
}


jQuery.fancybox.close();