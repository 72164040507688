jQuery($ => {
    $(document).on('click', '.js-preview-images a', function(e) {
        const link = $(e.target).closest('a');
        const i = Number(link.data('i'));
        const container = link.closest('.js-container');
        container.find('.js-preview-images .active').removeClass('active');
        link.closest('div').addClass('active');

        const imgNew = container.find('.js-main-image .img-' + i);
        const imgOld = container.find('.js-main-image .img').not(imgNew);

        imgNew.addClass('active');
        imgOld.removeClass('active');

        e.preventDefault();
    })

    $(document).on('click', '.js-main-image .is-img', function(e) {
        if ($(e.target).closest('.is-img').css('cursor') == 'pointer') {
            const src = $(e.target).closest('.is-img').find('img').attr('src');

            $('#modal-zoom img').attr('src', src);
            $.fancybox.open({ src: '#modal-zoom' });
            e.preventDefault();
        }
    })
});