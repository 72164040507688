import $ from "jquery";

// on click (by bootstrap event) -> change url
$(document).on('show.bs.tab', '.js-tab-sync', function (e) {
    let tab = $(e.target).closest('.js-tab-sync');
    let target = $(e.target).data('target') || $(e.target).attr('href');
    if (!target) {
        console.warn('Weird. Event did not have a bootstrap target', e);
        return;
    }

    const tabId = tab.attr('id');
    if (!isTabId(tabId)) {
        console.warn('Weird. Tab does not have a tab Id', tabId);
        return;
    }

    // Remove # at the beginning of target
    if (target.substring(0, 1) == '#') {
        target = target.substring(1);
    }

    const params = new URLSearchParams(location.search);
    params.set('tabs', 1);
    params.set(tabId, target);
    window.history.replaceState({}, '', `${location.pathname}?${params}`);
})

function isTabId(id) {
    return id.substring(0, 4) == 'tab-';
}

// on load -> change active tab
function sync_from_url() {
    const params = new URLSearchParams(location.search);

    // deprecated use of Filter parameter - use the first tab element available
    if(params.get('filter')) {
        const parent = $('.js-tab-sync').first();
        show_tab(parent, params.get('filter'));
    }

    if (params.get('tabs')) {
        for (const [key, value] of params.entries()) {
            if (isTabId(key)) {
                show_tab($('#' + key), value)
            }
        }
    }
    
}
function show_tab(parent, active) {
    if (!parent.length) {
        console.warn('Empty selector', parent);
        return;
    }
    const el = parent.find('a[href="#' + active + '"]') || parent.find('[data-target="#' + active + '"]');
    if (el.length) {
        el.tab('show');
    }
}

// On DOM ready
$(function() {
    // Bestseller -> Platin was loading invisibly otherwise ...
    setTimeout(sync_from_url, 1000);
})
