import Cookie from 'js-cookie'

const META_PIXEL_CONSENT_ID = 'J05PmJuvt36qTe';

const evaluateConsent = function() {
    const meta_service = UC_UI.getServicesBaseInfo().find(service => service.id === META_PIXEL_CONSENT_ID);
    if (!meta_service) {
        console.warn('Warning: Meta Pixel Service Id was not found. (This is deactivating the Meta Pixel)');
    }

    const meta_consented = meta_service?.consent?.status;

    if (meta_consented === true) {
        Cookie.set('consent_meta_pixel', 1);
        getFacebookPixel()?.loadPixel()
    } else {
        Cookie.remove('consent_meta_pixel');
        getFacebookPixel()?.disable()
    }
}

const getFacebookPixel = function() {
    // No JS error when Plugin PixelYourSite is not enabled (e.g. for logged-in Wordpress admins)
    if (typeof (getPixelBySlag) === 'undefined') return undefined;
    return getPixelBySlag('facebook');
}

window.addEventListener('UC_UI_CMP_EVENT', function (event) {
    const currentView = event?.detail?.type;
    const consentChanged = ['SAVE', 'ACCEPT_ALL', 'DENY_ALL'];

    if (consentChanged.includes(currentView)) {
        evaluateConsent();
    }
});

if (window.UC_UI && window.UC_UI.isInitialized()) {
    evaluateConsent()
} else {
    window.addEventListener('UC_UI_INITIALIZED', function (e) {
        evaluateConsent()
    });
}
