import $ from 'jquery'

export const enableParsley = $form => {
  if (!window.Parsley) {
    return
  }
  if ($form.data('parsleyEnabled')) {
    return
  }
  $form.data('parsleyEnabled', true)

  let parsley = $form.parsley({
    namespace: 'data-parsley-',
    trigger: 'blur change',
    animate: false,
    validationMinlength: 0,
    classHandler: function(field /*, isRadioOrCheckbox*/) {
      var el = $(field.$element)
      // Move Errors into the bootstrap-select class
      if (el.is('select') && el.hasClass('selectpicker')) {
        var newEl = el.next()
        if (newEl.hasClass('dropdown-toggle')) {
          return newEl
        }

        newEl = el
          .parent()
          .find('.dropdown-toggle')
          .first()
        if (newEl.hasClass('dropdown-toggle')) return newEl
      }

      // Move Checkbox errors into the label (where the text & checkbox is visually rendered)
      if (el.is('input[type="checkbox"]') || el.is('input[type="radio"]')) {
        return el.parent().find('label')
      }
      return el
    },
    errorsContainer: function(field /*, isRadioOrCheckbox*/) {
      var el = $(field.$element)
      var $container

      // Does a manual error container exist?
      var manualContainer = el.data('parsley-container')
      if (manualContainer) {
        $container = $(manualContainer)
        if ($container.length) return $container
      }

      // Error messages of selectboxes need to be aftere the bootstrap-select thing
      if (el.is('select') && el.hasClass('selectpicker')) {
        $container = el
          .parent()
          .parent()
          .find('.parsley-container')
        if ($container.length === 0)
          $container = $("<div class='parsley-container'></div>").insertAfter(
            el.parent()
          )
        return $container
      }
    },
    listeners: {
      onFormValidate: function(isFormValid, event) {
        if (!isFormValid) {
          // Abort form submission
          event.preventDefault()
          event.stopImmediatePropagation()
        }
      }
    }
  })

  parsley.on('form:success', formInstance => {
    if (!formInstance.submitEvent) return // This validation was not triggered by a submit
    formInstance.submitEvent.originalEvent.preventDefault()

    const event = document.createEvent('Event')
    event.initEvent('mp-validated', false, true)
    event.originalEvent = formInstance.originalEvent
    $form.get(0).dispatchEvent(event)
  })

  $form.find('.js-onchange-required').each(function () {
    $(this).on('change input blur', function (e) {
      const value = $(e.target).val();
      const newRequiredValue = ($(e.target).data('required-if') === value);

      const changeFields = $(e.target).closest('form').find('.js-change-required');

      changeFields.prop('required', newRequiredValue);
      changeFields.closest('.form-group').toggleClass('required', newRequiredValue);
      if (!newRequiredValue) {
        changeFields.each(function() { $(this).parsley().validate(); });
      }
    })
  })
}

import('parsleyjs').then(() => {
  window.Parsley.addValidator('ajax', {
    requirementType: 'string',
    priority: 3000,
    validateString: function(value, requirement, instance) {
      if (!ajax_errors) {
        // No ajax errors available yet
        return true
      }

      var myId = requirement

      if (myId && myId in ajax_errors && typeof ajax_errors[myId] == 'string') {
        return jQuery.Deferred().reject(ajax_errors[myId])
      }

      return true
    },
    validateMultiple: function(value, requirement, instance) {
      return this.validateString(value, requirement, instance)
    }
  })

  window.Parsley.addValidator('serverError', {
    requirementType: 'string',
    validateString(value, requirement, instance) {
      if (value == requirement) {
        return jQuery
          .Deferred()
          .reject(instance.$element.data('server-error-message'))
      }
      return true
    }
  })

  $.fn.addParsleyServerError = function(name, errorMessage) {
    if (!name) return this

    const field = this.find(`[name="${name}"]`)
    if (!field) return this

    field.attr('data-parsley-server-error', field.val())
    field.attr('data-server-error-message', errorMessage)
    this.parsley().refresh()

    return this
  }

  $.fn.removeParsleyServerErrors = function() {
    this.find('[data-parsley-server-error]')
      .removeAttr('data-parsley-server-error')
      .removeAttr('data-server-error-message')
    this.parsley().refresh()
    return this
  }

  import('parsleyjs/dist/i18n/de').then(messages_de => {
    let { ParsleyConfig = {} } = window

    window.ParsleyConfig = $.extend(true, {}, ParsleyConfig, {
      messages: messages_de
    })
  })

  $('form:not(.js-disable-parsley):not(#adminbarsearch)').each(function() {
    enableParsley($(this))
  })

  // Fix, see Parsley issue #412
  $('[data-parsley-equalto]').each(function(i, field) {
    var $field = $(field)
    $($field.attr('data-parsley-equalto')).on('change', function() {
      $field.parsley('validate')
    })
  })


  // Re-Validate when the selectpicker has changed its value
  $(document).on('changed.bs.select', '.selectpicker', function(
    e, clickedIndex, isSelected, previousValue
  ) {
    const target = $(e.target);
    if (!target.is('[required]')) return;
    if (!clickedIndex) return;

    console.warn('re-validate', {clickedIndex, previousValue})
    target
      .parsley()
      .validate()
  })
})

window.ajax_errors = {}

// conditional required for cta_form

