export default (container, createVue) => {
  createVue({
    name: container.data('vue-name') || 'Global Root',
    data() {
      return {
        visible: false
      }
    }
  })
}
