import Vue from "vue";
import store from "../store";
import each from "lodash/each";
import $ from "jquery";

import filters from "./filters/*.js";
each(filters, ({ default: filter }, name) => Vue.filter(name, filter));

import components from "./components/*.vue";
each(components, ({ default: component }, name) => {
  Vue.component(name, component);
});

import VueVirtualScroller from "vue-virtual-scroller";
Vue.use(VueVirtualScroller);

Vue.directive('visible', function (el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

// v-selectpicker
Vue.directive("selectpicker", {
  inserted(el) {
    $(el).selectpicker({
      width: "css-width",
      container: "body",
      noneSelectedText: 'Bitte auswählen...'
    });
    $(el).addClass('v-selectpicker');
    $(el).val(el.value);
    $(el).selectpicker("refresh");
  },
  componentUpdated(el) {
    $(el).val(el.value);
    $('.select-picker').selectpicker("refresh");
  },
  unbind(el) {
    $(el).selectpicker("destroy");
  },
});

// v-set-plz-pattern
function updatePlzPattern(el) {
  let pattern = '';
  let patternMsg = '';
  const countryID = el.value;
  if (+countryID === 1 /* Deutschland */) {
    pattern = '[0-9]{5}';
    patternMsg = 'Es werden 5 Ziffern erwartet.'
  }
  
  const plz = $(el).closest('.row').find('input[name*="postalCode"]');
  if (pattern) {
    plz.attr('pattern', pattern);
    plz.attr('data-parsley-error-message', patternMsg);
  } else {
    plz.removeAttr('pattern');
  }

  plz.parsley().validate();
}
Vue.directive('setPlzPattern', {
  inserted(el) {
    updatePlzPattern(el);
  },
  componentUpdated(el) {
    updatePlzPattern(el);
  },
});


// v-radioboxes-first-is-default
Vue.directive("radioboxesFirstIsDefault", {
  update(el) {
    if ($(el).find('input[type="radio"]:checked').length === 0) {
      $(el)
        .find('input[type="radio"]')
        .first()
        .prop("checked", true)
        .trigger('change')
    }
  },
});

Vue.filter('toPercent', function (value) {
  if (value === null) return 'n. verf.';
  value = Number(value) / 100;
  return value.toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits : 2});
});

import views from "./views/*.js";
$.fn.enableVue = function() {
  each(views, ({ default: createView }, name) => {
    this.find(".js-" + name).each(function() {
      const container = $(this);
      const defaultVueProps = {
        el: this,
        computed: {
          subsidiaries() {
            return this.$store.state.subsidiaries;
          },
          cart() {
            return this.$store.state.cart;
          },
          user() {
            return this.$store.state.user;
          },
        },
        store,
        methods: {
          mp_confirm,
        },
      };

      createView(
        container,
        (vueProps) =>
          new Vue({
            ...defaultVueProps,
            ...vueProps,
            computed: {
              ...defaultVueProps.computed,
              ...vueProps.computed,
            },
            methods: {
              ...defaultVueProps.methods,
              ...vueProps.methods,
            },
          })
      );
    });
  });
  return this;
};

$(() => {
  $("body").enableVue();
});
