import $ from 'jquery'
import Cookie from 'js-cookie'
import find from 'lodash/find'
import { getSearchParam } from "../../utils";

const items = $('#choose-counter-list button')
    .toArray().map(button => $(button).data('subsidiary')).filter(x => x)

const phoneOnline = $('#choose-counter-list button[data-phone]').data('phone');

const subsidiaryIDFromUrl = +getSearchParam('subsidiaryid');
if (subsidiaryIDFromUrl) {
    Cookie.set('subsidiaryID', subsidiaryIDFromUrl)
}

const state = {
    items,
    phoneOnline,
    selected: find(items, { id: +Cookie.get('subsidiaryID') })
}

const mutations = {
    toggle (state, subsidiary) {
        if (!!find([state.selected], subsidiary)) {
            state.selected = undefined
        } else {
            state.selected = find(items, subsidiary)
        }

        if (state.selected) {
            Cookie.set('subsidiaryID', state.selected.id)
        } else {
            Cookie.remove('subsidiaryID')
        }
    },
    unselect (state) {
        state.selected = undefined
        Cookie.remove('subsidiaryID')
    }
}

const getters = {
}

const actions = {
    init: {
        root: true,
        handler () {
            
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}