<template>
    <button class="btn btn-link" @click.prevent="reload">
        <span class="lnr lnr-sync" :class="{ rotating: loading }" />
    </button>
</template>

<script>
export default {
    props: {
        action: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        reload() {
            if (this.loading) return
            this.loading = true
            this.action().then(() => {
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        }
    }
}
</script>