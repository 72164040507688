<template>
  <div>
    <slot 
      :availability="currentAvailability" 
      :availability-count="currentAvailabilityCount" 
      :availability-raw="currentAvailabilityRaw" 
      v-bind:nb-local-availability="countLocalAvailability"
      v-bind:local-availability-in="listLocalAvailability"
      v-bind:subsidiary="selectedSubsidary">
    </slot>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy'
import find from 'lodash/find'

const getNameFromSubsidiaryId = function(id, subsidiaries) {
  const s = find(subsidiaries, { orderTypeID: id });
  return s?.name || id;
}

export default {
  props: {
    availability: {
      required: true,
    },
    availabilityCount: {
      required: false
    },
    availabilityRaw: {
      required: false,
    },
  },

  computed: {
    availabilityByOrderType() {
      if (!this.availability || Array.isArray(this.availability)) {
        return {}
      }
      return this.availability
    },
    selectedSubsidary() {
      return this.$store.state.subsidiaries.selected
    },
    localAvailability () {
      const { orderTypeID = 1 } = this.$store.state.subsidiaries.selected || {}
      // console.log(this.$store.state.subsidiaries.selected , orderTypeID, JSON.stringify(this.availabilityByOrderType))
      return this.availabilityByOrderType[orderTypeID] || 'none'
    },
    getLocalAvailableOrderTypes() {
      return Object.keys(pickBy(this.availabilityByOrderType,
        function (value, key) { return key != 1 /* not "online" */ && value === 'available'; }
      ));
    },
    countLocalAvailability () {
      return this.getLocalAvailableOrderTypes.length ?? 0;
    },
    listLocalAvailability() {
      if (!this.getLocalAvailableOrderTypes.length) return '';
      return this.getLocalAvailableOrderTypes.map(id => getNameFromSubsidiaryId(id, this.$store.state.subsidiaries.items)).join(', ');
    },
    onlineAvailability () {
      return this.availabilityByOrderType[1] || 'none'
    },
    anywhereAvailability () {
      if (this.onlineAvailability !== 'none') {
        return this.onlineAvailability
      }
      return this.localAvailability
    },
    currentAvailability () {
      return {
        local: this.localAvailability,
        online: this.onlineAvailability,
        anywhere: this.anywhereAvailability
      }
    },
    currentAvailabilityRaw() {
      const { orderTypeID = 1 } = this.$store.state.subsidiaries.selected || {};
      const unknownAvailabilityRaw = { label: '' };
      //console.log('availabilityRaw', this.availabilityRaw && this.availabilityRaw[orderTypeID]);
      return {
        local:  orderTypeID == 1 ? unknownAvailabilityRaw : 
          (this.availabilityRaw && this.availabilityRaw[orderTypeID] ? this.availabilityRaw[orderTypeID] : unknownAvailabilityRaw),
        online: this.availabilityRaw && this.availabilityRaw[1] ? this.availabilityRaw[1] : unknownAvailabilityRaw
      }
    },
    currentAvailabilityCount () {
      const { orderTypeID = 1 } = this.$store.state.subsidiaries.selected || {};
      return {
        local: orderTypeID == 1 ? 0 : (this.availabilityCount ? this.availabilityCount[orderTypeID] : 0) || 0,
        online: (this.availabilityCount ? this.availabilityCount[1] : 0) || 0
      }
    }
  }
}
</script>
