import each from 'lodash/each'
import Vuex from 'vuex'
import Vue from 'vue'
import $ from 'jquery'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const files = require('./modules/*.js')
const modules = {}
each(files, (file, key) => {
    if (key != 'index') {
        modules[key] = file.default
    }
})

const vuexPersist = new VuexPersist({
    key: 'mp-edelmetalle::cache',
    modules: ['cart'],
    storage: localStorage
})

const store = new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== 'production',
    plugins: [ vuexPersist.plugin ]
})

$(async () => {
    store.dispatch('init', $)
    const response = await fetch(location.origin + '/api/website/init')
    const data = (await response.json()).data
    store.dispatch('reloadFromJSON', data)
})

export default store