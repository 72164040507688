<template>
  <div class="prod-filter bg-secondary js-prod-filter">
    <div class="row">
      <div
        class="col-12 col-sm-6 col-md-3"
        v-for="(option, name) in options"
        :key="name"
      >
        <select
          type="text"
          v-selectpicker
          class="select-picker"
          style="width: 100%"
          data-width="100%"
          data-size="6"
          :title="option.label + ' auswählen'"
          :value="value[name]"
          @change="e => handleChange(name, e.target.value)"
        >
          <option value :title="option.label">Alle anzeigen</option>
          <option
            v-for="(value, slug) in option.values"
            :key="slug"
            :value="slug"
          >
            {{ value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleChange(name, value) {
      this.$emit('input', {
        ...this.value,
        [name]: value,
        ...(name === 'country' ? { serie: '' } : {})
      })
    }
  }
}
</script>
