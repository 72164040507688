import $ from 'jquery'

import('../jquery/mmenu').then(() => {

    /*------------------ MENU ------------------ */
    $("#menu").mmenu({
      // Options
      navbar: { title: "" },
      "extensions": [
        "position-back",
        "position-bottom",
        "fx-listitems-drop"
      ],
      menuButton: $("#menu-button"),
      closeOnResize: true
    },{
      // configuration
      clone: true,
      classNames: { selected: "active" }
    })
})